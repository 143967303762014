import axios from 'axios'
import {setNotification} from '../../../shared/Notification'
import {showDetail} from '../../../shared/showDetail'
import { findUpdateMatch } from '../../../repositories/cartRepositry'
import logout from '../../../shared/logout'

const initialState = {
  cart: {
    cartProducts: [],
    details: {},
    exclusionConfirmed: false,
    promoCodes: null,
    allowedProducts: null
  },
  carts: null,
  loading: false
}

export const cartStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    addProductToCart(state, data){
      findUpdateMatch(state, data)
    },
    setCart(state, cart){
      state.cart = cart
      if(cart){
        state.cart.promoCodes = cart.details.promo_codes ? [...cart.details.promo_codes.split(',')] : null
      }
    },
    setCartLoadStatus(state, status){
      state.loading = status
    },
    updateCartProductPricing(state, pricing){
      let product = _.find(state.cart.cartProducts, cp => cp.eclipse_id == pricing.eclipse_id)
      product.pricing = pricing
    },
    setProductCount(state, count){
      state.productCount = count
    },
    removeCartItem(state, id){
      let cartProducts = [...state.cart.cartProducts]
      state.cart.cartProducts = _.reject(cartProducts, cp => cp.product_id == id)
    },
    setCarts(state, carts){
      state.carts = carts
    },
    removeFromCartList(state, id){
      state.carts = _.reject([...state.carts], {id})
    },
    confirmExclusionNotice(state){
      state.cart.exclusionConfirmed = true
    },
    resetExclusionConfirm(state){
      state.cart.exclusionConfirmed = false
    },
    submitOrderNotes(state, orderNotes){
      state.cart.details.order_notes = orderNotes
    }
  },
  actions:{
    cartResetState(context){
      context.commit('resetState')
    },
    // Standard add product to cart from search results
    addToCart(context, product){
      const qty = parseInt(product.cartData.qty)
      const exclusion = product.prepaid_freight_exclusion?.message?.exclusion_type
      const promoReceive = this.state.entityStore.customerData ? this.state.entityStore.customerData.promo_receive_flag : true
      const vShow = product.cartData?.virtualShow
      const vShowDetail = showDetail(product)
      let params = {
        eclipse_id: product.eclipse_data.eclipse_id,
        quantity: qty,
        uom: product.eclipse_data.uom,
        comment: exclusion ? exclusion : null,
        vshow_item: vShow,
        dropship: product.cartData?.dropship,
        active_uom: product.cartData?.activeUom
      }
      axios.post('/api/v1/private/cart/add_to_cart', {cart: params}).then(res => {
        product.cartProduct = res.data.product
        const data = {
          product,
          vShow,
          vShowDetail
        }
        context.commit('addProductToCart', data)
      })
      // If customer is eligibe to receive promo and the product carries promo data
      // analyze and add promo code if users home(sell) branch is authorized for promo
      if(promoReceive && product.promo){  
        this.dispatch('addPromoCode', product.promo.promo_code)
      }
      // Add Virtual Show PromoCodes if not present
      if(product.cartData?.virtualShow){
        const cart = this.state.cartStore.cart
        if(!cart.details.vshow_cart){
          return
        }
        let promoCodes = []
        const showDetail = this.state.virtualShowStore.showDates
        const promoCode = showDetail.promo_code
        const dropShipCode = showDetail.dropship_promo_code
        const earlyBirdCode = showDetail.early_bird_promo_code
        const promoCodePresent = _.includes(cart.promoCodes, promoCode)
        const dropshipCodePresent = _.includes(cart.promoCodes, dropShipCode)
        const earlyBirdCodePresent = _.includes(cart.promoCodes, earlyBirdCode)
        if(!promoCodePresent){promoCodes.push(promoCode)}
        if(!dropshipCodePresent){promoCodes.push(dropShipCode)}
        if(!earlyBirdCodePresent && earlyBirdCode && product.early_bird){promoCodes.push(earlyBirdCode)}
        if(promoCodes.length > 0){this.dispatch('addPromoCode', promoCodes)}
      }
      // Refresh cart to catch qty break pricing from eclipse
      if(product.eclipse_data?.price_breaks?.length || vShow && vShowDetail?.qty_break1){this.dispatch('getCart')}
    },
    quickPadAdd(context, params){
      axios.post('/api/v1/private/cart/bulk_add', {product_list: params.productList, v_show: params.vShow}).then(res=>{
        if(res.status == 200){
          this.dispatch('setQuickPadPasteLoading', false)
          this.dispatch('getCart')
          setNotification('Product(s) Added To Cart!', 'success')
          this.dispatch('clearOrderPad')
        }
      })
    },
    cartItemIncreaseQty(context, product){
      context.commit('cartItemIncreaseQty', product)
    },
    cartItemDecreaseQty(context, product){
      context.commit('cartItemDecreaseQty', product)
    },
    removeCartItem(context, product){
      context.commit('removeCartItem', product.eclipse_id)
      axios.delete('/api/v1/private/cart/delete_cart_product', {params: {product_id: product.eclipse_id}}).then(res => {
        this.dispatch('getCart')
      })
    },
    clearCart(context){
      // Destroy all cart_product records on the back end
      axios.delete('/api/v1/private/cart/clear_cart').then(res => {
        if(res.status == 200){
          //getCart updates and clears front end cart
          this.dispatch('getCart')
        }
      })
    },
    getCart(context, new_cart_id = null){
      context.commit('setCartLoadStatus', true)
      const params = {new_cart_id}
      axios.get('/api/v1/private/cart/get_cart', {params}).then(res =>{
        context.commit('setCart', res.data.cart)
        context.commit('setCartLoadStatus', false)
      }).catch(error =>{
        if(error.response.data.msg == 'No User'){
          logout()
        }
      })
    },
    deleteCart(context, id){
      const params = {
        cart_id: id
      }
      axios.delete('/api/v1/private/manage_carts/delete_cart', {params}).then(res=>{
        // context.commit('setCart', res.data.current_cart)
        context.commit('removeFromCartList', res.data.destroyed_cart)
      })
    },
    updateProduct(context, cartProduct){
      context.commit('setCartLoadStatus', true)
      axios.post('/api/v1/private/cart/update_cart_product', cartProduct).then(res => {
        context.commit('updateCartProductPricing', res.data.pricing)
        context.commit('setCartLoadStatus', false)
      })
    },
    getCarts(context){
      axios.get('/api/v1/private/manage_carts/get_carts').then(res=>{
        if(res.data.error && res.data.error == 'No User'){
          logout()
        }else{
          context.commit('setCarts', res.data.carts)
        }
      })
    },
    confirmExclusionNotice(context){
      context.commit('confirmExclusionNotice')
    },
    resetExclusionConfirm(context){
      context.commit('resetExclusionConfirm')
    },
    addPromoCode(context, promoCode){
      axios.post('/api/v1/private/cart/add_promo_code', {promo_code: promoCode}).then(res=>{
        if(res.data.refresh) this.dispatch('getCart')
      })
    },
    removePromoCode(context, promoCode){
      axios.post('/api/v1/private/cart/remove_promo_code', {promo_code: promoCode}).then(res=>{
        if(res.data.refresh) this.dispatch('getCart')
      })
    },
    submitOrderNotes(context, orderNotes){
      axios.post('/api/v1/private/cart/submit_order_notes', {orderNotes}).then(res =>{
        context.commit('submitOrderNotes', orderNotes)
      })
    },
    async switchToStandardCart(context){
      return axios.get('/api/v1/private/manage_carts/get_standard_cart').then(res=>{
        this.dispatch('getCart')
      })
    }
  },
  getters:{
    cart: state => {return state.cart},
    cartProducts: state => {return state.cart.cartProducts},
    cartAllowedProducts: state => {return state.cart.allowedProducts},
    cartLoading: state => {return state.loading},
    cartTotal: state => {
      let prices = 0
      if(state.cart && state.cart.cartProducts.length > 0){
        _.each(state.cart.cartProducts, p => {
          if(p.pricing){
            prices += p.pricing.extended_price
          }
        })
      }
      return prices
    },
    cartProductCount: state => {return state.cart ? state.cart.cartProducts.length : 0},
    cartList: state => {return state.carts},
    exclusionConfirmed: state => {return state.cart.exclusionConfirmed},
    vShowCart: state => {return state.cart?.details.vshow_cart}
  }
}