<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12">  
        <!-- Cart -->
        <div
          v-if="view == 'cart'"
          style="position: relative;">
          <!-- Desktop Cart Header -->
          <div
            v-if="!loading"
            class="row sticky-top bg-white p-1"
            style="border-bottom: 1px solid lightgray; z-index:1;">
            <!-- Clear Cart Function -->
            <div class="col-md-12">
              <div class="float-start mt-1">
                <div class="btn-group">
                  <CartTools v-if="!confirmClear"/>
                  <button
                    style="width: 120px;"
                    class="btn btn-sm btn-link px-1"
                    v-if="!confirmClear && cartProducts && cartProducts.length > 0"
                    @click="toggleClear">
                    Clear Cart
                  </button>
                  <!-- Clear Cart Confirm -->
                  <button
                    v-if="confirmClear"
                    class="btn btn-sm btn-outline-danger animate__animated animate__bounceIn animate__faster">
                    <strong>Clear Cart, Are you sure?</strong>
                    <a
                      class="mx-1"
                      style="color: inherit;"
                      @click="clearCart">
                      Yes
                    </a>
                    <a
                      class="mx-1"
                      style="color: inherit;"
                      @click="toggleClear">
                      No
                    </a>
                  </button>
                  <!-- Toggle Order Notes Form -->
                  <button
                    v-if="!confirmClear"
                    class="btn btn-link px-1 btn-sm"
                    @click="toggleNotes">
                    Order Notes
                  </button>
                </div>
              </div>
              <div class="float-end d-none d-sm-block">
                <strong class="text-primary mt-2 me-3 float-start">
                  Cart Total:&nbsp;{{numeral(cartTotal).format('$0,0.00')}}          
                </strong>
                <button
                  v-if="allowed"
                  class="btn btn-primary btn-sm float-end"
                  style="width: 120px;"
                  href="javascript: void(0)"
                  :disabled="billToWarning || containsNonStockOutOfStock"
                  @click="gotoCheckout">
                  <i class="fa fa-shipping-fast"/>
                  Checkout
                </button>
              </div>
            </div>
            <OrderNotes
              :showNotes="showNotes"
              @closeNotes="toggleNotes"/>
            <!-- Non ship to account warning -->
            <div
              v-if="billToWarning"
              class="text-center my-5 alert alert-danger">
              <h5>
                <i class="fa fa-exclamation-triangle"/>
                Warning
              </h5>
              <strong class="text-danger">
                Your currently selected account is a BILL TO ONLY Account.
              </strong>
              <div class="mt-3">
                <button
                  type="button"
                  class="btn btn-outline-danger btn-sm"
                  @click="showShipToSelector">
                  Select a valid ship to location to continue
                </button>
              </div>
            </div>
            <!-- Unauthorized Items Warning -->
            <div
              v-if="!allowed"
              class="text-center my-1 alert alert-danger">
              <i class="fa fa-exclamation-triangle"/>
              <strong class="text-danger">
                Your cart contains items that are not permitted for sale to your current shipping location.
              </strong>
              <a
                href="#"
                class="text-danger"
                style="font-weight: 900;"
                @click="toggleUnauthFocus">
                {{showUnauth ? 'See All Products' : 'See Details'}}
              </a>
              <div
                v-if="showUnauth"
                class="mt-2 animate__animated animate__fadeInDown animate__fast">
                <strong>Please remove below items to proceed with checkout.</strong>
                <br>
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="removeUnauthItems">
                  <i class="fa fa-minus-circle"/>
                  Remove All
                </button>
              </div>
            </div>
          </div>
          <!-- Cart Products Loading -->
          <div
            v-if="loading && !initialLoad"
            class="row">
            <div class="col-md-12 text-center mt-5">
              <Spinner/>
            </div>
          </div>
          <!-- No Products Handler -->
          <div v-if="!loading && cartProducts && cartProducts.length == 0">
            <div class="row">
              <div class="col-md-12 text-center text-muted">
                <h4 class="mt-5">
                  No Products
                </h4>
              </div>
            </div>
          </div>
          <!-- Product Results -->
          <div
            v-for="p in cartProducts"
            :key="p.product_id">
            <CartRow
              :showUnauth="showUnauth"
              :loading="loading"
              :cartProduct="p"/>
          </div>
        </div>
        <!-- Checkout Form -->
        <div
          v-if="view == 'checkout'"
          class="animate__animated animate__fadeIn animate__faster p-2">
          <a
            class="btn btn-primary btn-sm"
            href="javascript: void(0)"
            @click="gotoCart">
            <i class="fa fa-chevron-left"/>
            Back
          </a>
          <CartForm/>
        </div>
      </div>
      <!-- Mobile total and checkout -->
      <div
        v-if="!loading && view == 'cart'"
        class="animate__animated animate__slideInUp animate__faster d-block d-sm-none mobile-total">
        <strong class="text-primary float-start text-light p-1">
          Cart Total:&nbsp;{{numeral(cartTotal).format('$0,0.00')}}          
        </strong>
        <button
          v-if="allowed"
          class="btn btn-outline-light btn-sm float-end"
          href="javascript: void(0)"
          :disabled="billToWarning || containsNonStockOutOfStock"
          @click="gotoCheckout">
          <i class="fa fa-shipping-fast"/>
          Checkout
        </button>
      </div>
    </div>
  </div>
</template>
 
<script>
  import CartRow from './CartRow.vue'
  import CartForm from './CartForm.vue'
  import OrderNotes from './OrderNotes.vue'
  import {toggleShipToSelector} from '../../../../shared/ShipToSelector'
  import Spinner from '../../../global/Spinner.vue'
  import CartTools from './CartTools.vue'
  import axios from 'axios'
  import { nonStockOutOfStock } from '../../../../shared/ProductFunctions'
  
  export default {
    inject: ['env'],
    data(){
      return{
        view: 'cart',
        showNotes: false,
        initialLoad: false,
        confirmClear: false,
        showUnauth: false
      }
    },
    components:{
      CartRow,
      CartForm,
      OrderNotes,
      Spinner,
      CartTools
    },
    created(){
      this.getShowDates()
      this.$store.dispatch('getCart')
      this.$store.dispatch('resetExclusionConfirm')
      this.$store.dispatch('populateCheckout')
      this.$store.dispatch('getCarts')
    },
    mounted(){
      this.scrollToTop()
    },
    watch:{
      allowed(newVal){
        if(newVal && this.showUnauth){
          this.showUnauth = false
        }
      }
    },
    methods:{
      getShowDates(){
        axios.get('/api/v1/private/virtual_show/get_show_dates').then(res => {
          if(res.data.show_dates){
            this.$store.dispatch('setShowDates', res.data.show_dates)
          }
        })
      },
      gotoCheckout(){
        this.view = 'checkout'
        this.scrollToTop()
      },
      gotoCart(){
        this.view = 'cart'
        this.scrollToTop()
      },
      scrollToTop(){
        document.getElementById('content').scrollTop = 0
      },
      toggleNotes(){
        this.showNotes = !this.showNotes
        if(this.showNotes){
          this.scrollToTop()
        }
      },
      toggleClear(){
        this.confirmClear = !this.confirmClear
      },
      showShipToSelector(){
        toggleShipToSelector('show')
      },
      clearCart(){
        this.$store.dispatch('clearCart')
        this.confirmClear = false
      },
      toggleUnauthFocus(){
        this.showUnauth = !this.showUnauth
      },
      removeUnauthItems(){
        let unauth = []
        this.cartProducts.forEach(function(cp){
          if(this.cartAllowedProducts.includes(cp.eclipse_id)) return
          unauth.push(cp)
        }.bind(this))
        
        unauth.forEach(function(ua){
          this.$store.dispatch('removeCartItem', ua)
        }.bind(this))
      }
    },
    computed:{
      loading(){
        const l = this.$store.getters.cartLoading
        if(!l && !this.initialLoad){
          this.initialLoad = true
        }
        return l
      },
      cart(){return this.$store.getters.cart},
      cartProducts(){
        if(this.initialLoad){
          return _.sortBy(this.$store.getters.cartProducts, cp => cp.id)
        }
      },
      cartTotal(){
        if(!this.loading){
          return this.$store.getters.cartTotal
        }
      },  
      cartAllowedProducts(){
        if(!this.loading){
          return this.$store.getters.cartAllowedProducts
        }
      },
      allowed(){
        if(!this.loading && this.cartProducts){
          let status = true
          this.cartProducts.forEach(cp => {
            let flag = _.includes(this.cartAllowedProducts, cp.eclipse_id)
            if(!flag) status = flag
          })
          return status
        }
      },
      billToWarning(){return this.$store.getters.billToWarning},
      containsNonStockOutOfStock(){
        if(this.env == 'LAN' && !this.cart.details.vshow_cart){
          let productState = []
          this.cartProducts.forEach(cp =>{
            productState.push(nonStockOutOfStock(this.env, cp.product_data))
          })
          return productState.includes(true)
        }
        return false
      }
    }
  }
</script>

<style scoped>
  .mobile-total{
    bottom: 75px;
    position: fixed;
    z-index: 2;
    background: var(--bs-success);
    filter: saturate(0.5);
    height: 54px;
    padding: 11px;
    width: 100%;
    border-top: 1px solid var(--bs-primary);
  }
</style>