<template>
  <div class="row">
    <div class="col-md-12">
      <!-- Header -->
      <div class="bg-primary" style="height:4em;">
        <div
          class="p-2"
          style="width:100%;">
        </div>
        <div class="clearfix"/>
      </div>
      <ul style="list-style-type:none;">
        <li v-if="admin && !adminMode && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="goto('Admin')">
            <i class="fa fa-user-shield"></i>
            Admin
          </a>
        </li>
        <li v-if="admin && adminMode && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="goto('PrivateHome')">
            <i class="fa fa-door-open"></i>
            Exit Admin
          </a>
        </li>
        <li v-if="!adminMode && settings.myAccount && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="goto('MyAccount')">
            <i class="fa fa-user"></i>
            My Account
          </a>
        </li>
        <li v-if="!adminMode && settings.userManagement && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="goto('UserManagement')">
            <i class="fa fa-users"></i>
            User Management
          </a>
        </li>
        <li v-if="!adminMode && settings.manageCarts && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            :style="user.estore_noorder ? 'pointer-events:none;opacity:0.25;' : ''"
            @click="goto('ManageCarts')">
            <i class="fa fa-shopping-cart"></i>
            Manage Carts
          </a>
        </li>
        <li v-if="!adminMode && settings.hidePricing && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="toggleHidePricing">
            <div class="form-check form-switch p-0">
              <label
                for="hidePricing"
                class="form-check-label">
                <i class="fa fa-eye-slash"></i>
                Hide Pricing
              </label>
              <input
                id="hidePricing"
                class="form-check-input ms-1"
                style="float:right; cursor: pointer;"
                type="checkbox"
                :checked="hidePricing">
            </div>
          </a>
        </li>
        <li v-if="!adminMode && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="toggleSuggested">
            <div class="form-check form-switch p-0">
              <label
                for="suggestedProducts"
                class="form-check-label">
                <i class="fa fa-boxes"></i>
                Suggested Products
              </label>
              <input
                id="suggestedProducts"
                class="form-check-input ms-1"
                style="float:right; cursor: pointer;"
                type="checkbox"
                :checked="user.type_ahead">
            </div>
          </a>
        </li>
        <li v-if="!adminMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="toggleQtyBreaks">
            <div class="form-check form-switch p-0">
              <label
                for="qtyBreakToggle"
                class="form-check-label">
                <i class="fa fa-expand-arrows-alt"/>
                Qty Breaks Expanded
              </label>
              <input
                id="qtyBreakToggle"
                class="form-check-input ms-1"
                style="float:right; cursor: pointer;"
                type="checkbox"
                :checked="user.qty_breaks_expanded">
            </div>
          </a>
        </li>
        <li v-if="!adminMode && managerRoles.includes(user.role) && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="toggleShowCost">
            <div
              class="form-check form-switch p-0"
              style="cursor: pointer;">
              <label
                class="form-check-label">
                <i class="fas fa-dollar-sign pe-1"></i>
                Show Cost
              </label>
              <input
                class="form-check-input ms-1"
                style="float:right;"
                type="checkbox"
                :checked="showCost">
            </div>
          </a>
        </li>
        <li v-if="!adminMode && settings.priceLists && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="goto('PriceLists')">
            <i class="fa fa-list"></i>&nbsp;
            Price Lists
          </a>
        </li>
        <li v-if="!adminMode && settings.binLabels && !vShowMode">
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="goto('BinLabels')">
            <i class="fa fa-print"></i>
            Bin Labels
          </a>
        </li>
        <li>
          <a
            class="btn btn-link text-primary my-2 user-menu"
            href="javascript:void(0)"
            @click="logOut">
            <i class="fa fa-sign-out-alt"></i>
            Log Out
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { managerRoles } from '../../../../shared/roles'
  export default {
    props:['vShowMode'],
    data(){
      return{
        countdown: null,
        timer: 0,
        managerRoles
      }
    },
    methods:{
      goto(view){
        // Clear Active Promo in filter store on entrance/exit of admin mode
        if(['PrivateHome', 'Admin'].includes(view)) this.$store.dispatch('clearActivePromo')
        // Route the user & close menu
        this.$router.push({name: view})
        this.$store.dispatch('closeRightMenu')
      },
      logOut() {
        this.$store.dispatch('eclipseLogOut')
      },
      toggleHidePricing(){
        this.$store.dispatch('toggleHidePricing')
      },
      toggleShowCost(){
        this.$store.dispatch('toggleShowCost')
      },
      toggleSuggested(){
        this.$store.dispatch('toggleSuggested')
      },
      toggleQtyBreaks(){
        this.$store.dispatch('toggleQtyBreaks')
      }
    },
    computed:{
      admin(){return this.$store.getters.adminStatus},
      settings(){return this.$store.getters.settings},
      hidePricing(){return this.$store.getters.hidePricing},
      showCost(){return this.$store.getters.showCost},
      adminMode(){return this.$router.currentRoute.value.path == '/admin'},
      user(){return this.$store.getters.user},
      settings(){return this.$store.getters.settings}
    },
    watch:{
      timer(NewVal){
        if (NewVal == 0) {
          clearInterval(this.countdown)
          this.timer = 0
          if(this.showCost){
            this.$store.dispatch('toggleShowCost')
          }
        }
      }
    },
    beforeDestroy(){
      clearInterval(this.countdown)
    }
  }
</script>

<style scoped>
  .user-menu{
    text-decoration: none;
    font-weight:700;
  }
  .btn-link:hover{
    filter: none;
  }
</style>