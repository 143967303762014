<template>  
  <div class="modal fade" id="shipToSelector" tabindex="-1" role="dialog" aria-labelledby="modelTitleId" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content shadow-custom">
        <div class="modal-header">
          <h5 class="modal-title">Select Ship To</h5>
            <button
              type="button"
            class="btn-close float-end"
            data-bs-dismiss="modal"
            aria-label="Close"/>
        </div>
        <div
          v-if="entityData"
          class="modal-body py-0">
          <table class="table table-sm table-striped">
            <tbody>
              <template v-if="entityData.entity && entityData.entity.ship_tos">
                <tr
                  class="row mt-3"
                  v-for="(s, i) in entityData.entity.ship_tos"
                  :key="s.id">
                  <div class="col-lg-2 col-6 text-center text-success d-flex justify-content-center align-items-center">
                    <b v-if="entityData.activeShipTo.entity_id == s.id && !shipToLoading">
                      <i class="fa fa-check"></i>
                      &nbsp;Active
                    </b>
                    <button
                      v-if="entityData.activeShipTo.entity_id != s.id && !shipToLoading"
                      :class="mobile ? 'btn btn-link' : 'btn btn-primary btn-sm'"
                      @click="selectShipTo(s)">
                      Select
                    </button>
                    <Spinner v-if="shipToLoading == s.id"/>
                  </div>
                  <!-- Default Ship To -->
                  <div
                    v-if="isBillTo && entityData.entity.ship_tos.length > 1"
                    class="col-lg-3 col-6 text-center d-flex justify-content-center align-items-center">
                    <div v-if="!defaultShipToLoader">
                      <div v-if="defaultShipTo && defaultShipTo == s.id">
                        <i class="fa fa-check-circle text-success"/>
                        Default Ship To
                      </div>
                      <button
                        v-else
                        :class="mobile ? 'btn btn-link' : 'btn btn-primary btn-sm'"
                        @click="setDefault(s.id)">
                        Set as Default
                      </button>
                    </div>
                    <div v-else>
                      <Spinner class="text-center"/>
                    </div>
                  </div>
                  <div class="col-lg-7 text-center d-flex justify-content-center align-items-center">
                    ({{s.id}}) {{s.name}}
                    <br>
                    {{s.street1}}
                    {{s.street2}}
                    {{s.city}}, {{s.state_code}}, {{s.zip_code}}
                  </div>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Spinner from '../../global/Spinner.vue'
  
  export default {
    data(){
      return{
        shipToLoading: null,
        mobile: false
      }
    },
    components:{
      Spinner
    },
    created(){
      this.mobileCheck()
      
      window.addEventListener('resize', function(){
        this.mobileCheck()
      }.bind(this))
    },
    watch:{
      entityData(newData){
        if(newData){
          this.shipToLoading = null
        }
      }
    },
    methods:{
      selectShipTo(shipTo){
        this.shipToLoading = shipTo.id
        this.$store.dispatch('selectShipTo', shipTo)
      },
      setDefault(id){
        this.$store.dispatch('setDefaultShipTo', id)
      },
      mobileCheck(){this.mobile = window.innerWidth < 992}
    },
   computed:{
      entityData(){return this.$store.getters.getEntity},
      isBillTo(){return this.$store.getters.isBillTo},
      defaultShipTo(){return this.$store.getters.user.default_ship_to},
      defaultShipToLoader(){return this.$store.getters.defaultShipToLoader}
   } 
  }
</script>

<style scoped>
  .shadow-custom{
    box-shadow: 2px 2px 40px black !important;
  }
</style>