<template>
  <div
    class="w-100 h-100 p-2 shadow-sm animate__animated animate__fadeIn animate__faster highlight"
    :ref="'card-' + product.product_id"
    style="border: 1px solid lightgray; border-radius: 10px; position:relative; background:white;">
    <Indicator
        :product="product"
        :virtualShow="virtualShow"/>
    <!-- Favorites Editing / delete -->
    <FavDeleteBtn
        v-if="editingFavorites"
        @deleteFavProduct="deleteFavProduct"/>
    <Favorite
      v-if="!virtualShow && !editingFavorites"
      :product="product"/>
    <div
      v-if="!qtyBreakVisible"
      :data-bs-toggle="this.product.eclipse_data ? 'modal' : ''"
      :data-bs-target="this.product.eclipse_data ? '#productDetail' : ''"
      @click="setActiveProduct"
      class="d-flex justify-content-center mb-2"
      style="height:150px;overflow:hidden;"
      :style="this.product.eclipse_data ? 'cursor: pointer;' : ''">
      <img 
        v-if="visible"
        class="img-fluid mt-3"
        :src="product.image_url"
        style="object-fit:contain;"
        @error="imgError">
    </div>
    <ProductPrice
      :product="product"
      :key="product.product_id"
      :virtualShow="virtualShow"
      @activeUom="setActiveUom"/>
    <div>
      <QtyBreaks
        :product="product"
        :virtualShow="virtualShow"/>
      <Exclusion :product="product"/>
      <div v-if="!qtyBreakVisible">
        <!-- Closeout Price if Applicable -->
        <!-- Description -->
        <div
          style="overflow: hidden; height: 38px; cursor: pointer; line-height: 18px;"
          data-bs-toggle="modal"
          data-bs-target="#productDetail"
          @click="setActiveProduct">
          <span class="text-muted">{{product.description}}</span>
        </div>
        <StockStatus
          v-if="internalRoles.includes(role)"
          :product="product"/>
        <!-- Details -->
        <div
          class="mt-1"
          style="font-size: 12px; margin-bottom: 13rem;">
          <div>
            <Prop65 :product="product"/>
            <!-- UPC -->
            <div>
              <span class="text-muted">UPC: {{product.upc}}</span>
            </div>
            <!-- Case Qty -->
            <div v-if="caseQty">
              <span class="text-muted">Case Qty: {{caseQty}}</span>
            </div>
            <!-- Pallet Qty -->
            <div v-if="palletQty">
              <span class="text-muted">Pallet Qty: {{palletQty}}</span>
            </div>
            <!-- Customer Part Number -->
            <div v-if="product.customer_part_number">
              <span class="text-muted">Part Number: {{product.customer_part_number.part_number}}</span>
            </div>
          </div>
          <LastOrdered :product="product"/>
        </div>
        <Availability
          class="card-avail"
          style="border-top: 1px solid lightgray; font-size: 12px;"
          :product="product"
          :virtualShow="virtualShow"
          :activeUom="activeUom"/>
        <div
          class="px-3"
          style="position: absolute; bottom:5px; left:0px; width:100%">
          <QtyForm
            :product="product"
            :virtualShow="virtualShow"
            :activeUom="activeUom"/>
        </div>
      </div>
    </div>
  </div> 
</template>

<script>
  import comingSoon from '../../../../shared/comingSoon'
  import {caseQuantity,palletQuantity} from '../../../../shared/ProductFunctions'
  import ProductPrice from './price/ProductPrice.vue'
  import Indicator from './Indicator.vue'
  import FavDeleteBtn from '../favorites/FavDeleteBtn.vue'
  import Favorite from './Favorite.vue'
  import QtyBreaks from './QtyBreaks.vue'
  import Exclusion from './Exclusion.vue'
  import StockStatus from './StockStatus.vue'
  import { internalRoles } from '../../../../shared/roles'
  import Prop65 from './Prop65.vue'
  import LastOrdered from './LastOrdered.vue'
  import Availability from './Availability.vue'
  import QtyForm from './QtyForm.vue'

  export default {
    props: ['product', 'editingFavorites', 'virtualShow', 'loadImages'],
    data(){
      return{
        qtyBreakVisible: false,
        visible: false,
        activeUom: null,
        internalRoles
      }
    },
    components:{
      ProductPrice,
      Indicator,
      FavDeleteBtn,
      Favorite,
      QtyBreaks,
      Exclusion,
      StockStatus,
      Prop65,
      LastOrdered,
      Availability,
      QtyForm
    },
    mounted(){
      if(this.$refs[`card-${this.product.product_id}`]){
        let observer = new IntersectionObserver(this.handleIntersection)
        observer.observe(this.$refs[`card-${this.product.product_id}`])
        this.observer = observer
      }
    },
    destroyed() {
      this.observer.disconnect();
    },
    watch:{
      loadImages(newVal){
        if(newVal) this.visible = this.loadImages.includes(this.product.product_id)
      }
    },
    methods:{
      setActiveProduct(){
        if(this.product.eclipse_data) this.$store.dispatch('setActiveProduct', this.product)
      },
      handleIntersection(entries){
        for (let entry of entries) {
          if (entry.isIntersecting){
            this.visible = true
          }
        }
      },
      imgError(e){comingSoon(e)},
      setActiveUom(activeUom){this.activeUom = activeUom},
      deleteFavProduct(){this.$emit('deleteFavProduct', this.product.product_id)}
    },
    computed:{
      eclipseData(){
        if(this.product.eclipse_data) return this.product.eclipse_data
      },
      caseQty(){
        return caseQuantity(this.product)
      },
      palletQty(){
        return palletQuantity(this.product)
      },
      role(){return this.$store.getters.user.role}
    }
  }
</script>

<style scoped>
  .card-avail{
    position: absolute;
    left: 0px;
    bottom: 65px;
    width: 100%;
    padding: 0px;
  }
</style>