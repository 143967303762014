import axios from "axios"
import moment from "moment/moment"

const initialState = {
  reorderPadProducts: null,
  favoriteProducts: null,
  categories: null,
  initialized: false,
  orderPad: [],
  vShowAllowed: false,
  vShowCameraResults: false,
  vShowCameraSearch: null,
  termsWarning: true,
  showDates: null,
  activeView: 'browse'
}

export const virtualShowStore = {
  state:{...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setVshowReorderPadProducts(state, productIds){
      state.reorderPadProducts = productIds
    },
    setVshowFavorites(state, productIds){
      state.favoriteProducts = productIds
    },
    setVshowCategories(state, categories){
      state.categories = categories
    },
    vShowInitialized(state, status){
      state.initialized = status
    },
    addOrderPadProduct(state, product){
      let match = _.find(state.orderPad, o => o.orderData.product_id == product.orderData.product_id)
      if(match){
        match.orderData.qty += product.orderData.qty
      }else{
        state.orderPad.push(product)
      }
    },
    deleteOrderPadProduct(state, product_id){
      state.orderPad = _.reject([...state.orderPad], o => o.orderData.product_id == product_id)
    },
    updateOrderPadProduct(state, orderData){
      let match = _.find(state.orderPad, o => o.orderData.product_id == orderData.product_id)
      match.qty = (match.qty += orderData.qty)
    },
    clearOrderPad(state){
      state.orderPad = []
    },
    vShowCameraToggle(state){
      state.vShowCameraResults = !state.vShowCameraResults
    },
    vShowCameraSearch(state, params){
      state.vShowCameraSearch = params
    },
    clearCamera(state){
      state.vShowCameraSearch = null
    },
    setShowDates(state, showDates){
      state.showDates = showDates
    },
    setVshowAllowed(state, status){
      state.vShowAllowed = status
    },
    vShowClear(state){
      state.reorderPadProducts = null
      state.favoriteProducts = null
      state.categories = null
      state.initialized = false
      state.orderPad = []
      state.vShowCameraResults = false
      state.vShowCameraSearch = null
      state.showDates = null
      state.termsWarning = true
    },
    closeTermsWarning(state){
      state.termsWarning = false
    },
    vShowSetView(state, view){
      state.activeView = view
    }
  },
  actions:{
    vShowResetState(context){
      context.commit('resetState')
    },
    setVshowReorderPadProducts(context, productIds){
      context.commit('setVshowReorderPadProducts', productIds)
    },
    setVshowFavorites(context, productIds){
      context.commit('setVshowFavorites', productIds)
    },
    setVshowCategories(context, categories){
      context.commit('setVshowCategories', categories)
    },
    vShowInitialized(context, status){
      context.commit('vShowInitialized', status)
    },
    addOrderPadProduct(context, product){
      context.commit('addOrderPadProduct', product)
    },
    deleteOrderPadProduct(context, product_id){
      context.commit('deleteOrderPadProduct', product_id)
    },
    updateOrderPadProduct(context, orderData){
      context.commit('updateOrderPadProduct', orderData)
    },
    clearOrderPad(context){
      context.commit('clearOrderPad')
    },
    vShowCameraToggle(context){
      context.commit('vShowCameraToggle')
    },
    vShowCameraSearch(context, params){
      context.commit('vShowCameraSearch', params)
    },
    clearCamera(context){
      context.commit('clearCamera')
    },
    setShowDates(context, showDates){
      context.commit('setShowDates', showDates)
    },
    getVirtualShowStatus(context){
      const today = moment().format('YYYY-MM-DD')
      axios.get('/api/v1/private/virtual_show/get_show_dates').then(res=>{
        if(res.data.show_dates && res.data.show_dates.start_date <= today){
          context.commit('setShowDates', res.data.show_dates)
          context.commit('setVshowAllowed', true)
        }else{
          context.commit('setShowDates', null)
          context.commit('setVshowAllowed', false)
        }
      }).catch(error=>{
        if(error.response.data.msg == 'No User'){
          this.dispatch('eclipseLogOut')
        }
      })
    },
    vShowClear(context){
      context.commit('vShowClear')
    },
    closeTermsWarning(context){
      context.commit('closeTermsWarning')
    },
    vShowSetView(context, view){
      context.commit('vShowSetView', view)
    }
  },
  getters:{
    vShowReorderPadProducts: state => {return state.reorderPadProducts},
    vShowFavorites: state => {return state.favoriteProducts},
    vShowCategories: state => {return state.categories},
    vShowInitialized: state => {return state.initialized},
    vShowOrderPad: state => {return state.orderPad},
    vShowCameraResults: state => {return state.vShowCameraResults},
    vShowCameraSearch: state => {return state.vShowCameraSearch},
    vShowDates: state => {return state.showDates},
    vShowAllowed: state => {return state.vShowAllowed},
    vShowTermsWarning: state => {return state.termsWarning},
    vShowActiveView: state => {return state.activeView}
  }  
}