<template>
  <div>
    <Maintenance v-if="maintenanceMode"/>
    <div
      v-else
      :class="adminMode ? 'admin-body' : 'main-body'">
      <LanNav v-if="!loggedOn && env == 'LAN'"/>
      <EjwNav v-if="!loggedOn && env == 'EJW'"/>
      <CheneyNav v-if="!loggedOn && env == 'EJW-CHENEY'"/>
      <router-view
        class="router-view-style"
        :style="loggedOn || route == '/public_products' ? 'overflow: hidden;' : 'overflow-y: scroll;'"
        id="router-view"/>
      <ToTop v-if="!loggedOn"/>
      <Cookies/>
      <LoadingScreen v-if="initializing"/>
    </div>
  </div>
</template>

<script>
  import LanNav from './components/public/lan/app/Nav.vue'
  import EjwNav from './components/public/ejw/app/Nav.vue'
  import CheneyNav from './components/public/cheney/app/Nav.vue'
  import Cookies from './components/global/Cookies.vue'
  import ToTop from './components/public/shared/ToTop.vue'
  import Maintenance from './components/global/Maintenance.vue'
  import LoadingScreen from './components/global/LoadingScreen.vue'

  export default {
    name: 'App',
    inject:['env'],
    components:{
      LanNav,
      EjwNav,
      CheneyNav,
      Cookies,
      ToTop,
      Maintenance,
      LoadingScreen
    },
    data(){
      return{
        // maintenance mode must be null to start  
        // with or else the regular website will 
        // flash before maintenance mode appears
        maintenance_mode: null
      }
    },
    created(){
      // This listenter handles clicks outside of the flyout menus to reset the 
      // menu views to a default state for logged in users only
      document.addEventListener('click', function(e){
        if(this.$store.state.sessionStore.loggedOn){
          // Main Flyout Menus
          let leftMenu = document.getElementById('leftMenu')
          let rightMenu = document.getElementById('rightMenu')
          // Icon Launchers
          let navRightShow = document.getElementById('userMenuTop')
          let navCart = document.getElementById('navCart')
          let navCartMobile = document.getElementById('navCartMobile')
          let navRightMobileShow = document.getElementById('userMenuMobile')
          let navLeftMobileShow = document.getElementById('navMenuMobile')
          let mobileFilter = document.getElementById('mobileFilter')
          // Check for element interaction else close menus
          if(
            rightMenu && rightMenu.contains(e.target) ||
            e.target.id.includes('flyout') ||
            navRightShow && navRightShow.contains(e.target) ||
            navRightMobileShow && navRightMobileShow.contains(e.target) ||
            navCart && navCart.contains(e.target) ||
            navCartMobile && navCartMobile.contains(e.target)){
            return
          }else if(
            leftMenu && leftMenu.contains(e.target) ||
            navLeftMobileShow && navLeftMobileShow.contains(e.target) ||
            mobileFilter && mobileFilter.contains(e.target)){
            return
          }
          else{
            this.$store.dispatch('setSideMenus')
          }
        }
      }.bind(this))

      
      
      // Session validity and app version to user version checker 
      this.$store.dispatch('eclipseSessionCheck')
      
      this.$store.dispatch('maintenanceCheck')

      setInterval(function(){
        this.$store.dispatch('eclipseSessionCheck')
      }.bind(this), 1000 * 60 * 60)

      // Check user version matches db version - used to force logout and session state refresh when needed
      setInterval(function(){
        this.$store.dispatch('appVersionCheck')
      }.bind(this), 180000)

    },
    computed:{
      loggedOn(){return this.$store.getters.loggedOn},
      adminMode(){return this.$router.currentRoute.value.path == '/admin'},
      route(){return this.$router.currentRoute.value.path},
      maintenanceMode(){return this.$store.getters.maintenanceMode},
      initializing(){return this.$store.getters.initializing}
    }
  }
</script>

<style>
  .card{
    border-radius:20px;
  }
  .main-body{
    background: white;
  }
  .admin-body{
    background: darkslategray;
    color: white;
  }
  .router-view-style{
    height: 100svh;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
  }
  .router-view-style::-webkit-scrollbar {
    display: none;
  }
  .sticky-top{
    z-index: 1 !important;
  }
</style>

