import axios from 'axios'
import router from '../../../router'
import _ from 'lodash'
import {internalRoles} from '../../../shared/roles'

const initialState = {
  eclipseSession: null,
  entityID: null,
  loggedOn: false,
  loginAttempts: 0,
  admin: false,
  error: null,
  company: null,
  hidePricing: false,
  showCost: false,
  settings: null,
  branches: null,
  user: null,
  lastSessionCheck: null,
  hideAccountInquiry: false,
  unlockCodes:[],
  forgotPassword: false,
  defaultShipToLoader: false,
  maintenanceMode: false,
  initializing: false
}

// Reset all Vuex stores back to intial state
const globalResetState = function(store){
  store.dispatch('accountResetState')
  store.dispatch('branchResetState')
  store.dispatch('cartResetState')
  store.dispatch('checkoutResetState')
  store.dispatch('entityResetState')
  store.dispatch('favoriteResetState')
  store.dispatch('feedbackResetState')
  store.dispatch('productResetState')
  store.dispatch('reorderResetState')
  store.dispatch('tmResetState')
  store.dispatch('viewResetState')
  store.dispatch('sessionResetState')
  store.dispatch('vShowResetState')
}

// Used with eclipseSessionCheck and appVersionCheck
// if either checks come back false boot the user out and reset state
// Also maintenance check happens inside both of these checks, we force the
// refresh to present the maintenance mode if applicable
const resetSessionState = function(res, store){
  if(res.data.status){
    return
  }else{
    globalResetState(store)
    router.push({name: 'PublicHome'})
    router.go()
  }
}

// Determine if redirect is required
const redirectTo = function(){
  const from = router.currentRoute.value.redirectedFrom
  const currentPath = router.currentRoute.value.fullPath
  const redirectPath = from?.fullPath
  const routes = _.reject(_.map(router.getRoutes(), 'path'), r => r.includes('cart'))
  if(redirectPath){
    return redirectPath != currentPath && routes.includes(redirectPath.split('?')[0])
  }
  return false
}

export const sessionStore = {
  state: {...initialState},
  mutations:{
    resetState(state){
      const s = initialState
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
    setLoginStatus(state, status){
      state.loggedOn = status
    },
    setSession(state, sessionData){
      state.eclipseSession = sessionData.eclipse_session
      state.entityID = sessionData.entity_id
      state.admin = sessionData.role == 'admin'
      state.hideAccountInquiry = sessionData.hide_account_inquiry
      state.company = sessionData.company
      state.unlockCodes = sessionData.unlock_codes
      state.settings = sessionData.settings
      state.user = sessionData.user
      state.forgotPassword = sessionData.user.forgot_password
      state.branches = sessionData.branches
      state.loggedOn = true
      if(state.user.default_ship_to){
        let shipTo = {
          id: state.user.default_ship_to
        }
        this.dispatch('selectShipTo', shipTo)
      }
    },
    setError(state, error){
      state.loginAttempts += 1
      state.error = error
    },
    clearAttempts(state){
      state.loginAttempts = 0
    },
    clearLoginErrors(state){
      state.error = null
    },
    toggleHidePricing(state){
      state.hidePricing = !state.hidePricing
    },
    toggleShowCost(state){
      state.showCost = !state.showCost
    },
    submitUnlockCode(state, code){
      if(_.includes(state.unlockCodes, code)){
        return
      }else{
        state.unlockCodes.push(code.toLowerCase())
      }
    },
    setLastSessionCheck(state, time){
      state.lastSessionCheck = time
    },
    removeForgotPasswordBlock(state){
      state.forgotPassword = false
    },
    setDefaultShipTo(state, id){
      state.user.default_ship_to = id
    },
    setDefaultShipToLoader(state, status){
      state.defaultShipToLoader = status
    },
    setMaintenanceMode(state, status){
      state.maintenanceMode = status
    },
    setInitializing(state, status){
      state.initializing = status
    },
    toggleSuggested(state, user){
      let updatedUser = {...state.user}
      updatedUser.type_ahead = user.type_ahead
      state.user = updatedUser
    },
    toggleQtyBreaks(state, user){
      state.user.qty_breaks_expanded = user.qty_breaks_expanded
    }
  },
  actions:{
    sessionResetState(context){
      context.commit('resetState')
    },
    eclipseSessionCheck(context){
      if(!this.state.sessionStore.loggedOn) return
      axios.get('/api/v1/private/session/session_status').then((res) => {
        resetSessionState(res, this)
      })
    },
    appVersionCheck(context){
      if(!this.state.sessionStore.loggedOn) return
      axios.get('/api/v1/private/session/version_status').then((res) => {
        resetSessionState(res, this)
      })
    },
    maintenanceCheck(context){
      axios.get('/api/v1/private/session/maintenance_mode').then((res) => {
        context.commit('setMaintenanceMode', res.data.maintenance_mode)
      })
    },
    eclipseLogIn(context, formData){
      axios.post('/api/v1/private/session/login', formData).then((res) => {
        if(res.data.error){
          context.commit('setError', res.data.error)
        }
        else{
          context.commit('setInitializing', true)
          context.commit('setError', null)
          context.commit('setSession', res.data)
          context.commit('clearAttempts')
          this.dispatch('setSideMenus')
          this.dispatch('getEntityDetails')
          this.dispatch('getVirtualShowStatus')
          if(redirectTo()){
            const from = router.currentRoute.value.redirectedFrom
            router.push({path: from.fullPath, query: {...from.query, redirect: true}})
          }else{
            router.push({name: 'PrivateHome'})
          }
          if(internalRoles.includes(res.data.role)){
            this.dispatch('setRepDashboardView', true)
          }
          axios.get('/api/v1/private/session/prep_promo_data')
          axios.get('/api/v1/private/session/prep_type_ahead').then(res=>{
            if(res.data.type_ahead) context.commit('setInitializing', false)
          }).catch(error=>{
            context.commit('setInitializing', false)
            this.dispatch('setNotification', {
              message: 'Error Loading Typeahead Data',
              messageType: 'danger'
            })
          })
        }
      })
    },
    eclipseLogOut(context){
      globalResetState(this)
      router.push({name: 'PublicHome'})
      axios.get('/api/v1/private/session/logout').then(res=>{
        window.location.reload()
      })
    },
    clearLoginErrors(context){
      context.commit('clearLoginErrors')
    },
    toggleHidePricing(context){
      context.commit('toggleHidePricing')
      if(context.getters.showCost){
        context.commit('toggleShowCost')  
      }
    },
    toggleShowCost(context){
      context.commit('toggleShowCost')
    },
    submitUnlockCode(context, code){
      context.commit('submitUnlockCode', code)
      axios.post('/api/v1/private/session/submit_unlock_code', {unlock_code: code})
    },
    removeForgotPasswordBlock(context){
      context.commit('removeForgotPasswordBlock')
    },
    setDefaultShipTo(context, id){
      context.commit('setDefaultShipToLoader', true)
      axios.post('/api/v1/private/session/set_default_ship_to', {id}).then(res => {
        if(res.data.default_ship_to){
          context.commit('setDefaultShipTo', res.data.default_ship_to)
          context.commit('setDefaultShipToLoader', false)
        }
      })
    },
    toggleSuggested(context){
      axios.post('/api/v1/private/session/toggle_suggested').then(res=>{
        context.commit('toggleSuggested', res.data.user)
      })
    },
    toggleQtyBreaks(context){
      axios.post('/api/v1/private/session/toggle_qty_breaks').then(res=>{
        context.commit('toggleQtyBreaks', res.data.user)
      })
    }
  },
  getters:{
    loggedOn: state => {return state.loggedOn},
    sessionStatus: state => {return state},
    customerID: state => {return state.entityID},
    loginError: state => {return state.error},
    loginAttempts: state => {return state.loginAttempts},
    adminStatus: state => {return state.admin},
    hidePricing: state => {return state.hidePricing},
    showCost: state => {return state.showCost},
    sessionCompany: state => {return state.company},
    unlockCodes: state => {return state.unlockCodes},
    user: state => {return state.user},
    hideAccountInquiry: state => {return state.hideAccountInquiry},
    eclipseSession: state => {return state.eclipseSession},
    settings: state => {return state.settings},
    forgotPassword: state => {return state.forgotPassword},
    defaultShipToLoader: state => {return state.defaultShipToLoader},
    maintenanceMode: state => {return state.maintenanceMode},
    branches: state => {return state.branches},
    initializing: state => {return state.initializing}
  },
}