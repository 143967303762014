<template>
  <div class="container mb-5" style="z-index:1;">
    <form
      v-if="checkout.form"
      name="checkoutForm"
      @submit.prevent="submit"
      ref="checkoutForm">
      <div>
        <h4 class="text-center">Checkout</h4>
      </div>
      <!-- Form Fields -->
      <div class="row">
        <!-- Ship To -->
        <div class="col-md-6">
          <label for="shipTo">Ship To</label>
          <select
            id="shipTo"
            class="form-control form-control-sm text-muted"
            style="cursor: pointer;"
            :disabled="loading"
            @change="refreshCart"
            v-model="checkout.activeShipTo">
            <option
              v-for="s in checkout.availableShipTos"
              :key="s.entity_id"
              :value="s.entity_id">
              {{s.name}} ({{s.entity_id}}), {{s.address.street_line_1}} {{s.address.city}} {{s.address.state}} {{s.address.postal_code}}
            </option>
          </select>
        </div>
        <!-- Ship Via -->
        <div class="col-md-6">
          <label for="shipVias">Ship Via</label>
          <select
            id="shipVias"
            class="form-control form-control-sm text-muted"
            style="cursor:pointer;"
            :disabled="loading"
            v-model="checkout.form.shipVia">
            <option
              v-for="s in checkout.shipViaOptions"
              :value="s.value"
              :key="s.value">
              {{s.text}}
            </option>
          </select>
        </div>
        <!-- PO Number -->
        <div class="col-md-6">
          <label for="poNumber">PO Number</label>
          <input
            id="poNumber"
            class="form-control form-control-sm"
            :disabled="loading"
            type="text"
            v-model="checkout.form.poNumber"
            @keydown.enter.prevent
            placeholder="Enter PO Number"
            :required="['PO Number', 'Both PO and Rel Number'].includes(poRelRequired)">
        </div>
        <!-- Customer Release field will only show if required for customer -->
        <div
          v-if="!internalRoles.includes(user.role) && ['Release Number', 'Both PO and Rel Number'].includes(poRelRequired)"
          class="col-md-6">
          <label for="custRelease">Release Number</label>
          <input
            id="custRelease"
            class="form-control form-control-sm"
            :disabled="loading"
            type="text"
            v-model="checkout.form.customerRelease"
            @keydown.enter.prevent
            placeholder="Enter Release Number"
            required>
        </div>
        <!-- EJW Job Name Field for sales team only -->
        <div
          v-if="env.includes('EJW') && internalRoles.includes(user.role)"
          class="col-md-6">
          <label for="sideMark">Side Mark / Job Name</label>
          <input
            id="sideMark"
            class="form-control form-control-sm"
            :disabled="loading"
            type="text"
            v-model="checkout.form.jobName"
            @keydown.enter.prevent
            placeholder="Enter Job Name"
            required>
        </div>
        <!-- Requested / Required Date -->
        <!-- Virtual Show Week Selector -->
        <div
          v-if="cart.details.vshow_cart && company != 'Lancaster Canada'"
          class="col-md-6">
          <VshowShipWeek
            :form="checkout.form"
            :company="company"/>
        </div>
        <div
          v-else
          class="col-md-6">
          <label for="requestDate">Requested Date</label>
          <input
            id="requestDate"
            class="form-control form-control-sm"
            :disabled="loading"
            type="date"
            :max="cart.details.vshow_cart && company == 'Lancaster Canada' ? '2025-04-30' : null"
            v-model="checkout.form.requiredDate"
            placeholder="Required Date">
        </div>
        
        <!-- Ordered By -->
        <div class="col-md-6">
          <label for="orderedBy">Ordered By</label>
          <input
            id="orderedBy"
            class="form-control form-control-sm"
            :disabled="loading"
            type="text"
            v-model="checkout.form.orderedBy"
            placeholder="Enter Name"
            @keydown.enter.prevent
            :required="env.includes('EJW')">
        </div>
        <!-- Shipping Instructions -->
        <div class="col-md-6">
          <label for="instructions">Shipping Instructions</label>
          <textarea
            id="instructions"
            class="form-control form-control-sm"
            rows="1"
            :disabled="loading"
            type="text"
            v-model="checkout.form.instructions"
            @keydown.enter.prevent
            placeholder="Enter Instructions"/>
        </div>
        <!-- Internal Notes for sales staff -->
        <div
          v-if="internalRoles.includes(user.role)"
          class="col-md-6">
          <label for="internalNotes">
            Internal Notes
            <span class="ms-1 text-muted">(customer does not see these)</span>
          </label>
          <textarea
            id="internalNotes"
            class="form-control form-control-sm"
            rows="2"
            :disabled="loading"
            type="text"
            v-model="checkout.form.internalNotes"
            @keydown.enter.prevent
            placeholder="Enter Notes"/>
        </div>
        <!-- Submit order as credit option for sales staff -->
        <div
          v-if="internalRoles.includes(user.role) && !cart.details.vshow_cart && creditsAllowed"
          class="col-md-6">
          <CreditRequest
            :form="checkout.form"
            :shipViaOptions="checkout.shipViaOptions"/>
        </div>
        <!-- Promo Codes -->
        <div
          v-if="promoReceive && !error && !cart.details.vshow_cart"
          class="col-md-6">
          <div>
            <label
              v-if="promoCodes && promoCodes.length > 0"
              for="promoCodes"
              class="text-muted">
              Promo Codes Applied:
            </label>
          </div>
          <button
            v-for="(p, index) in promoCodes"
            :key="index"
            class="btn btn-primary btn-sm m-1 animate__animated animate__bounceIn animate__faster"
            :disabled="loading || promoProductPresent(p)"
            type="button">
            {{p}}&nbsp;
            <i
              v-if="!promoProductPresent(p)"
              class="fa fa-times text-white"
              @click="removePromoCode(p)"/>
          </button>
          <!-- Promo Reminder -->
          <div
            v-if="promoReminder"
            class="text-primary animate__animated animate__slideInUp animate__faster">
            <label for="promoCodes">
              <i class="fa fa-info-circle"/>
              Click 'Add' to apply promo
            </label>
          </div>
          <!-- Promo already applied Message-->
          <div
            v-if="promoPresent"
            class="text-danger animate__animated animate__bounceIn animate__faster">
            <label for="promoCodes">
              <i class="fa fa-exclamation-triangle"/>
              Promo Code Already Applied
            </label>
          </div>
          <!-- Promo Form -->
          <form
            name="promoForm"
            @submit.prevent="addPromoCode"
            class="mt-3">
            <div class="input-group">
              <input
                id="promoCodes"
                type="text"
                placeholder="Add Promo Code"
                v-model="promoCode"
                required
                class="form-control form-control-sm"
                :disabled="loading">
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="loading">
                Add
              </button>
            </div>
          </form>
        </div>
        <!-- Prepaid Freight -->
        <div class="col-md-6">
          <PrepaidFreight
            v-if="!cart.details.vshow_cart"
            :cartProducts="this.cartProducts"/>
        </div>
        <!-- Virtual Show Indicator -->
        <div
          v-if="cart.details.vshow_cart"
          class="col-md-12">
          <VshowIndicator
            :cart="cart"
            :form="checkout.form"/>
        </div>
        <!-- Freight Exclusion item warning -->
        <div
          v-if="exclusionWarning"
          class="col-md-12">
          <WarningIndicator
            :exclusionWarning="exclusionWarning"
            :exclusionConfirmed="exclusionConfirmed"
            @acknowledged="acknowledge"/>
        </div>
      </div>
      <!-- Ship with next prepaid order checkbox -->
      <div class="col-md-12">
        <div class="float-end my-1">
          <div class="pretty p-icon p-smooth">
            <input
              type="checkbox"
              v-model="checkout.form.shipWithNextPrepaid"/>
            <div class="state p-success">
              <i class="icon fa fa-check"></i>
              <label>Ship with next prepaid order</label>
            </div>
          </div>
        </div>
        <div class="clearfix"/>
      </div>
      <!-- Total & Complete Order -->
      <div class="col-12 bottom">
        <div
          v-if="env.includes('EJW') && !exclusionWarning"
          class="text-muted text-center">
          <small>
            *Rolled, long and heavy goods plus any equipment may incur additional freight charges that will be quoted prior shipping.
          </small>
        </div>
        <h4
          v-if="!loading && !error"
          class="animate__animated animate__fadeInDown animate__faster">
          <span class="float-start">Total: </span>
          <span class="float-end">
            <span v-if="!loading">
              {{numeral(cartTotal).format('$0,0.00')}}
            </span>
          </span>
          <div class="clearfix"></div>
        </h4>
        <CheckoutError
          v-if="error"
          :error="error"
          :shipTo="checkout.activeShipTo"/>
        <div
          class="d-grid"
          v-if="!error">
          <!-- Exclusions Present -->
          <button
            v-if="exclusionWarning && !exclusionConfirmed"
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#excludeConfirm"
            disabled>
            <i class="fa fa-shipping-fast"/>
            Complete Order
          </button>
          <button
            v-else
            type="submit"
            class="btn btn-primary"
            :disabled="processing || loading || cartTotal == 0 || !allowed || promoCode.length > 0 || billToWarning || noOrderEntry">
            <i
              v-if="!processing"
              class="fa fa-shipping-fast"/>
            <i
              v-if="processing"
              class="fa fa-spinner fa-spin"/>
            Complete Order
          </button>
          <div
            v-if="noOrderEntry"
            class="text-center text-danger">
            <strong>This account is flagged as "NO ORDER ENTRY", please contact Customer Service.</strong>
          </div>
        </div>
        <div class="text-muted">
          <span class="me-1">Need Help?</span>
          <div
            class="d-inline me-2"
            style="white-space: nowrap;">
            <span class="me-1">Call:</span>
            <a :href="'tel:' + contactBranch.phone">
              {{ env == 'EJW-CHENEY' ? contactBranch.phone2 : contactBranch.phone }}
            </a>
          </div>
          <div
            class="d-inline me-2"
            style="white-space: nowrap;">
            <span class="me-1">Email:</span>
            <a :href="'mailto:' + email">{{ email }}</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
  import Spinner from '../../../global/Spinner.vue'
  import PrepaidFreight from './PrepaidFreight.vue'
  import WarningIndicator from './WarningIndicator.vue'
  import CreditRequest from './CreditRequest.vue'
  import {internalRoles} from '../../../../shared/roles'
  import CheckoutError from './CheckoutError.vue'
  import VshowIndicator from './VshowIndicator.vue'
  import VshowShipWeek from './VshowShipWeek.vue'
  import { promoProductV2} from '../../../../shared/promoHelpers'
  import { exclusionWarning } from '../../../../shared/freight'
  import csContactEmail from '../../../../shared/csEmail'

  export default {
    inject: ['env'],
    data(){
      return{
        internalRoles,
        promoCode: '',
        promoReminder: false,
        promoPresent: false
      }
    },
   components:{
     Spinner,
     PrepaidFreight,
     WarningIndicator,
     CreditRequest,
     CheckoutError,
     VshowIndicator,
     VshowShipWeek
   },
   watch:{
    promoCode(newVal){
      if(newVal && newVal.length > 0){
        this.promoReminder = true
      }else{
        this.promoReminder = false
      }
    }
   },
   created(){
    if(this.env.includes('EJW')) this.$store.dispatch('freightAmounts')
   },
   mounted(){
      // set show ship vias
      if(this.cart.details.vshow_cart){
        this.checkout.shipViaOptions = [
          {value: 'Show', text: 'Show'}
        ]
        this.checkout.form.shipVia = this.checkout.shipViaOptions[0].text
      }
   },
   computed:{
      checkout(){return this.$store.getters.checkout},
      company(){return this.$store.getters.sessionCompany},
      activeShipTo(){return this.checkout.activeShipTo},
      cart(){return this.$store.getters.cart},
      cartTotal(){return this.$store.getters.cartTotal},
      cartProducts(){return _.sortBy(this.$store.getters.cartProducts, cp => cp.id)},
      cartAllowedProducts(){return this.$store.getters.cartAllowedProducts},
      allowed(){
        let status = true
        this.$store.getters.cartProducts.forEach(cp => {
          let flag = _.includes(this.cartAllowedProducts, cp.eclipse_id)
          if(!flag){
            status = flag
          }
        })
        return status
      },
      exclusionWarning(){return exclusionWarning(this.cartProducts) && !this.pickup},
      exclusionConfirmed(){return this.$store.getters.exclusionConfirmed},
      loading(){return this.$store.getters.cartLoading},
      processing(){return this.$store.getters.checkoutProcessing},
      user(){return this.$store.getters.user},
      promoCodes(){
        const base = this.$store.getters.cart?.details.promo_codes
        if(base){
          return base.split(',')
        }else{
          return null
        }
      },
      promoReceive(){return this.$store.getters.promoReceive},
      billToWarning(){return this.$store.getters.billToWarning},
      error(){return this.$store.getters.checkoutError},
      contactBranch(){return this.$store.getters.contactBranch},
      ejw(){return this.env == 'EJW'},
      creditsAllowed(){return this.$store.getters.settings.submitCredits},
      poRelRequired(){
        const entity = this.$store.getters.getEntity.activeShipTo.entity
        return entity.po_required
      },
      noOrderEntry(){return this.$store.getters.getEntity.customerData.no_order_entry || false},
      email(){return csContactEmail(this.env)},
      pickup(){return this.checkout.form.shipVia == 'CPUP FOB ORIGIN'}
    },
    methods:{
      acknowledge(){
        this.$store.dispatch('confirmExclusionNotice')
      },
      submit(){
        this.$store.dispatch('completeOrder')
      },
      togglePromoPresentMsg(){
        this.promoPresent = !this.promoPresent
        setTimeout(function(){
          this.promoPresent = !this.promoPresent
        }.bind(this), 3000)
      },
      addPromoCode(){
        const promoCode = this.promoCode.toUpperCase()

        if(this.promoCodes){
          const match = _.find(this.promoCodes, p => p == promoCode)
          if(match){
            this.promoCode = ''
            this.togglePromoPresentMsg()
            return
          }else{
            this.$store.dispatch('addPromoCode', promoCode)
          }
        }else{
          this.$store.dispatch('addPromoCode', promoCode)
        }
        this.promoCode = ''
        document.activeElement.blur()
      },
      removePromoCode(code){
        this.$store.dispatch('removePromoCode', code)
      },
      refreshCart(){
        this.$store.dispatch('getCart')
      },
      promoProductPresent(code){
        let codes = []
        _.each(this.cartProducts, cp => {
          const pp = promoProductV2(cp)
          const code = pp ? pp.promo.promo_code : null
          if(pp && code && !codes.includes(code)){
            codes.push(code)
          }
        })
        return _.includes(codes, code) ? true : false
      }
    }
  }
</script>

<style scoped>
  .bottom{
    margin-top: 20px;
    border-top: 1px solid var(--bs-primary);
    padding-top: 20px;
  }
  .input-group{
    z-index: 0;
  }
</style>