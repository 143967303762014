<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 mt-2 text-center">
        <h4 class="text-center">Terms & Details</h4>
      </div>
      <div class="col-md-12 mt-3">
        <ul>
          <li>
            <strong>Order Dates</strong>
            <ul>
              <li>Jan. 20th - Feb. 9th, 2025</li>
            </ul>
          </li>
          <li>
            <strong>Shipping</strong>
            <ul>
              <li>To assist our distribution centers ship show orders in a timely fashion, all customers will be given two ship weeks to pick from in the cart.</li>
              <li>All orders must ship by April 30, 2025. Any open order or backorder will be cancelled after April 30th.</li>
            </ul>
          </li>
          <li>
            <strong>Dropships</strong>
            <ul>
              <li>Dropships are shipped directly from the manufacturer. Because of this, Lancaster does not have control of when orders ship. Each dropship is subject to each vendor's prepaid and freight policies. If you have any questions regarding dropships, please reach out to your rep or customer service (orders@lancasterco.com).</li>
            </ul>
          </li>
          <li>
            <strong>Pool Buys</strong>
            <ul>
              <li>Pool buy orders contain items or full assortments that are not normally stocked in your designated distribution center. Lancaster will order these items based on show purchases. Please allow additional shipping time for these types of products.</li>
            </ul>
          </li>
          <li>
            <strong>Extended Terms</strong>
            <ul>
              <li>Net 60 day terms are available to customers to attend the show in Orlando. All non-show attendees will be given their everyday terms.</li>
            </ul>
          </li>
          <li>
            <strong>Spiffs & Prizes</strong>
            <ul>
              <li>Customers who placed their show orders using the Lancaster eStore during the pre-show period are still eligible to collect spiffs and prize points based on these orders in Orlando. Please visit the registration desk and ask for an official order report to do so.</li>
            </ul>
          </li>
          <li>
            <strong>Minimum Order</strong>
            <ul>
              <li>$2,000 USD</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>