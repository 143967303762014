<template>
  <div class="container">
    <div class="row">
      <div class="col-md-12 mt-2  ">
        <h4 class="text-center">Terms & Details</h4>
      </div>
      <div class="col-md-12 mt-3">
        <ul style="list-style-type: none;">
          <li>
            <strong>Order Dates</strong>
          </li>          
          <ul>
            <li>Show Attending Customers:  Jan. 20th – Feb. 9th, 2025</li>
            <li>Non-Attending Customers:  Jan. 20th – Feb. 28th, 2025</li>
          </ul>
        <li>
          <strong>Shipping</strong>
        </li>
        <ul>
          <li>The first available ship date is February 3, 2025. All orders must ship by April 30, 2025. Any open order or backorder will be cancelled after April 30th.</li>
        </ul>
        <li>
          <strong>Order Details</strong>
        </li>
        <ul>
          <li>There is a minimum order size of $1,000 CAD.</li>
          <li>Each ship-to location is allowed 2 show orders.</li>
        </ul>
        <li>
          <strong>Extended Terms</strong>
        </li>
        <ul>
          <li>Net 60-day terms are available to customers to attend the show in Orlando. All non-show attendees and orders placed after February 9th, will be given their everyday terms.</li>
        </ul>
        <li>
          <strong>Spiffs & Prizes</strong>
        </li>
        <ul>
          <li>Customers who placed their show orders using the eStore during the pre-show period (Jan. 20th – Feb. 1st) are still eligible to collect spiffs and prize points based on these orders in Orlando. Please visit the registration desk and ask for an official order report to do so.</li>
          <li>You must be present in Orlando to collect any spiffs or prize points.</li>
        </ul>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
  ul{
    line-height: 2em;
  }
</style>