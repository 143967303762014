<template>
  <div class="helloWorld">  
    <VshowToggle v-if="vShowAllowed"/>
    <div class="container">
      <VideoDecode v-if="bShowScanner"></VideoDecode>
    </div>
  </div>
</template>

<script>
import { BarcodeReader } from "dynamsoft-javascript-barcode";
// import "./src/dbr"; // import side effects. The license, engineResourcePath, so on.
import VideoDecode from "./VideoDecode.vue";
import ImgDecode from './ImgDecode.vue';
import VshowToggle from './VshowToggle.vue'

export default {
  data() {
    return {
      bShowScanner: true,
      bShowImgDecode: false
    };
  },
  async created(){
    BarcodeReader.license = 'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNTMyMjE2LVRYbFhaV0pRY205cVgyUmljZyIsIm1haW5TZXJ2ZXJVUkwiOiJodHRwczovL21sdHMuZHluYW1zb2Z0LmNvbS8iLCJvcmdhbml6YXRpb25JRCI6IjEwMTUzMjIxNiIsInN0YW5kYnlTZXJ2ZXJVUkwiOiJodHRwczovL3NsdHMuZHluYW1zb2Z0LmNvbS8iLCJjaGVja0NvZGUiOi0xNDQ0Mzg4Nzl9'
    BarcodeReader.engineResourcePath = "https://cdn.jsdelivr.net/npm/dynamsoft-javascript-barcode@9.6.40/dist/"
  },
  async mounted() {
    //Load the library on page load to speed things up.
    try {
      await BarcodeReader.loadWasm();
    } catch (ex) {
      let errMsg;
      if (ex.message.includes("network connection error")) {
        errMsg = "Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.";
      } else {
        errMsg = ex.message||ex;
      }
      console.error(errMsg);
      alert(errMsg);
    }
  },
  components: {
    VideoDecode,
    ImgDecode,
    VshowToggle
  },
  methods: {
    showScanner() {
      this.bShowScanner = true;
      this.bShowImgDecode = false;
    },
    showImgDecode() {
      this.bShowScanner = false;
      this.bShowImgDecode = true;
    }
  },
  computed:{
    vShowAllowed(){return this.$store.getters.vShowAllowed}
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 1.5em;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 0 10px;
}

a {
  color: #42b983;
}

.applogo {
  height: 25px;
}

.helloWorld {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: #455a64;
}

button {
  font-size: 1.5rem;
  margin-bottom: 2vh;
  border: 1px solid black;
}

span {
  font-size: 0.8rem;
}

.latest-result {
  display: block;
  margin: 0;
  padding: 0.4rem 0.8rem;
  color: inherit;
  width: 80vw;
  border: none;
  font-size: 1rem;
  border-radius: 0.2rem;
  text-align: center;
}

.latest-result::placeholder {
  color: #b0bec5;
}

.latest-result:focus {
  outline: none;
  box-shadow: 0.1rem 0.4rem 0.8rem #fe8e14;
}

#results {
  border: 1px dashed grey;
  overflow: auto;
  width: 80vw;
  padding: 2vmin;
  margin-bottom: 3vh;
  height: 15vh;
}

#results ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  font-size: 0.8rem;
}

.resultText {
  color: #ce5e04;
}

.bigger {
  font-size: large;
  margin-bottom: 2%;
}

.container {
  margin: 2vmin auto;
  text-align: center;
  font-size: medium;
  /* height: 40vh; */
  width: 80vw;
}
</style>
